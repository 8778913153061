import { useTranslate } from '/machinery/I18n'
import { useBooleanFormField, useFormField } from '@kaliber/forms'
import { Tooltip } from '/features/buildingBlocks/reward-calculator/Tooltip'
import { useClientConfig } from '/machinery/ClientConfig'

import styles from './Toggles.css'

export function Toggles({ fields }) {
  const config = useClientConfig()
  const { data: salaryData } = config.salary
  const { __ } = useTranslate()
  const { basicPay, holidayPay, extraMonth, ebb, scale } = fields

  const { state } = useFormField(scale)
  const { value: currentScale } = state ?? {}
  const { ebbMultiplier } = salaryData.find(x => x.scale === parseInt(currentScale, 10)) ?? {}

  return (
    <div className={styles.component}>
      <Toggle field={basicPay} label={__`basic-salary`} />
      <Toggle field={holidayPay} label={`${__`holiday-money`} (8%)`} />
      <Toggle field={extraMonth} label={__`extra-month`} />
      <ToggleTooltip ebbValue={ebbMultiplier} field={ebb} label={__`employee-benefit-budget`} />
    </div>
  )
}

function ToggleTooltip({ field, label, ebbValue }) {
  const { __ } = useTranslate()

  return (
    <Base {...{ field, label, ebbValue }}>
      <Tooltip title={__`employee-benefit-budget`} text={__`explain-tooltip`} />
    </Base>
  )
}

function Toggle({ field, label }) {
  return (
    <Base {...{ field, label }} />
  )
}

function Base({ field, label, ebbValue = undefined, children = undefined }) {
  const { name, state, eventHandlers: { onChange } } = useBooleanFormField(field)
  const { value } = state

  return (
    <div className={styles.componentBase}>
      <div className={styles.content} id={`${name}-label`}>
        <span>{label}</span>
        {ebbValue && <span> &nbsp;({Number(ebbValue * 100)}%)&nbsp; </span>}
        {children}
      </div>

      <label className={styles.toggleContainer}>
        <input
          aria-label={label}
          aria-checked={value}
          className={styles.inputToggle}
          type="checkbox"
          role="switch"
          checked={value}
          {...{ name, onChange }}
        />
        <span className={styles.slider} />
      </label>
    </div>
  )
}
