import { useSpring, animated, useSpringRef } from 'react-spring'
import { useFormField, useFormFieldValue } from '@kaliber/forms'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { Toggles } from '/features/buildingBlocks/reward-calculator/Toggles'
import { HoursPerWeek } from '/features/buildingBlocks/reward-calculator/HoursPerWeek'
import { calculateSalaryByScale } from '/features/buildingBlocks/reward-calculator/calculateSalaryByScale'
import { useRewardCalculatorForm } from './useRewardCalculatorForm'
import { useClientConfig } from '/machinery/ClientConfig'

import styles from './RewardCalculator.css'

import closeIcon from '/images/icons/close.raw.svg'

export function RewardCalculator({ link, initialScale = '07', onClose = undefined }) {
  const { __ } = useTranslate()
  const config = useClientConfig()
  const { data: salaryData } = config.salary

  const initialData = React.useMemo(
    () => salaryData.find(item => item.identifier === initialScale) ?? salaryData.find(x => x.identifier === '07'),
    [initialScale, salaryData]
  )

  const form = useRewardCalculatorForm({ initialData })
  const { fields } = form

  return (
    <div className={styles.component}>
      <div className={styles.periodAndClose}>
        <PeriodView layoutClassName={styles.periodLayout} field={fields.period} />
        {onClose && <CloseButton layoutClassName={styles.closeLayout} {...{ onClose }} />}
      </div>

      <HoursPerWeek
        field={fields.hours}
        layoutClassName={styles.hoursLayout}
        salaryScaleField={fields.scale}
        {...{ form }}
      />

      <SalaryScale
        layoutClassName={styles.scaleLayout}
        field={fields.scale}
        {...{ initialScale, salaryData }}
      />

      <Result
        layoutClassName={styles.resultLayout}
        {...{ form, initialData }}
      />

      <Toggles {...{ fields }} />

      {link?.url && link?.label && (
        <ButtonLinkPrimary
          layoutClassName={styles.buttonLayout}
          dataX='link-to-detail-page'
          label={link.label}
          href={link.url}
        />
      )}

      <p className={styles.explainText}>{__`reward-calculator-salary-explain`}</p>
    </div>
  )
}

function CloseButton({ onClose, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <button
      type='button'
      onClick={() => onClose()}
      data-x='close-overlay'
      aria-label={__`close-overlay`}
      className={layoutClassName}
    >
      <Icon icon={closeIcon} />
    </button>
  )
}

function PeriodView({ field, layoutClassName }) {
  const { __ } = useTranslate()
  const { name, state, eventHandlers: { onChange } } = useFormField(field)
  const { value } = state

  return (
    <div role="radiogroup" className={cx(styles.componentPeriodView, layoutClassName)}>
      <label className={cx(styles.periodLabel, value === 'month' && styles.active)}>
        <input
          id={`${name}_month`}
          type='radio'
          value='month'
          checked={value === 'month'}
          className={styles.inputPeriod}
          aria-checked={value === 'month'}
          data-x='monthly-view'
          {...{ onChange, name }}
        />
        {__`monthly`}
      </label>

      <label className={cx(styles.periodLabel, value === 'year' && styles.active)}>
        <input
          id={`${name}_year`}
          type='radio'
          value='year'
          checked={value === 'year'}
          className={styles.inputPeriod}
          aria-checked={value === 'year'}
          data-x='yearly-view'
          {...{ onChange, name }}
        />
        {__`yearly`}
      </label>
    </div>
  )
}

function SalaryScale({ salaryData, field, layoutClassName }) {
  const { __ } = useTranslate()
  const { name, state, eventHandlers } = useFormField(field)

  const { value } = state
  const parsedScale = parseInt(value, 10)
  const currentScaleData = salaryData.find(({ scale: s }) => s === parsedScale)
  const scaleLabel = currentScaleData?.label || currentScaleData.scale

  return (
    <div className={cx(styles.componentSalaryScale, layoutClassName)}>
      <p>{__`salary-scale`}</p>
      <input
        id={name}
        aria-label={__`input-label`}
        aria-valuemin={salaryData[0].scale}
        aria-valuemax={salaryData.length}
        aria-valuenow={Math.round(value)}
        step={0.01}
        className={styles.scaleInput}
        min={salaryData[0].scale}
        max={salaryData.length}
        type='range'
        data-x='salary-scale'
        onKeyDown={handleKeyDown}
        {...eventHandlers}
        {...{ name, value }}
        style={{
          '--value': value,
          '--min': salaryData[0].scale,
          '--max': salaryData.length,
        }}
      />
      <div className={styles.valuesScales}>
        <span className={styles.standardValue}>{salaryData[0].scale}</span>
        <span className={styles.scaleValue}>{scaleLabel}</span>
        <span className={styles.standardValue}>{'SKB'}</span>
      </div>
    </div>
  )

  function handleKeyDown(/** @type {import('react').KeyboardEvent} */e) {
    if ((e.key === 'ArrowRight' || e.key === 'ArrowUp') && value !== salaryData.length) {
      e.preventDefault()
      eventHandlers.onChange(value + 1)
    }

    if ((e.key === 'ArrowLeft' || e.key === 'ArrowDown') && value !== salaryData[0].scale) {
      e.preventDefault()
      eventHandlers.onChange(value - 1)
    }
  }
}

function Result({ form, layoutClassName }) {
  const config = useClientConfig()
  const { data: salaryData } = config.salary
  const { __ } = useTranslate()
  const formValue = useFormFieldValue(form)

  const { salaryMin, salaryMax } = calculateSalaryByScale(formValue, salaryData)

  return (
    <div
      aria-label={`${__`minimum`} ${formatSalary(salaryMin)} euro, ${__`maximum`} ${formatSalary(salaryMax)} euro`}
      aria-live="polite"
      className={cx(styles.componentResult, layoutClassName)}
    >
      <Salary label={__`minimum`} value={Math.round(salaryMin)} />
      <Salary label={__`maximum`} value={Math.round(salaryMax)} />
    </div>
  )
}

function Salary({ label, value }) {
  const { value: salary } = useNumberCounterSpring({ value })

  return (
    <div className={styles.componentSalary}>
      <span className={styles.label}>{label}</span>
      <span aria-hidden="true" className={styles.value}>
        <animated.span>{salary}</animated.span>
      </span>
    </div>
  )
}

function useNumberCounterSpring({ value }) {
  const [showAnimatedValue, setShowAnimatedValue] = React.useState(false)

  const [{ number: salary }] = useSpring({
    onStart: () => setShowAnimatedValue(true),
    onRest: () => setShowAnimatedValue(false),
    config: { tension: 170, friction: 26, precision: 0.001 },
    number: value || 0,
  }, [value])

  return {
    value: showAnimatedValue
      ? salary.to(x => formatSalary(x))
      : formatSalary(value)
  }
}

function formatSalary(salary) {
  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(salary)
}
